<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="accountTransactions.data"
    :items-per-page="50"
    v-bind="$attrs"
    loading-text="Carregando... por favor espere"
  >
    <template v-slot:[`item.description`]="{ item }">
      <account-transaction-label :account-transaction="item" />
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <status-chip :value="item.status" />
    </template>

    <template v-slot:[`item.date`]="{ item }">
      {{ $format.dateBr(item.date) }}
    </template>
    <template v-slot:[`item.payment_method`]="{ item }">
      <app-payment-method-chip :value="item.payment_method" />
    </template>
    <template v-slot:[`item.value`]="{ item }">
      <app-money-label
        :value="item.value"
        :canceled="item.status == 'canceled'"
      />
    </template>
    <template v-slot:[`item.account`]="{ item }">
       <account-label  :account="item.account"/>
    </template>
  </v-data-table>
</template>
<script>
import AccountLabel from '../../account/sections/AccountLabel.vue';
import AccountTransactionLabel from "../../account/sections/AccountTransactionLabel.vue";
import AppPaymentMethodChip from "../../app/sections/AppPaymentMethodChip.vue";
import StatusChip from "../../app/sections/StatusChip.vue";
export default {
  components: { AccountTransactionLabel, StatusChip, AppPaymentMethodChip, AccountLabel },
  props: {
    accountTransactions: {},
  },
  data() {
    return {
      headers: [
        {
          text: "Conta",
          value: "account",
          sortable: false,
          width: "20%",
        },
        { text: "Descrição", value: "description", sortable: false },
        {
          text: "Data",
          value: "date",
          sortable: false,
          align: "center",
          width: "12%",
        },
        {
          text: "Meio Pg",
          value: "payment_method",
          sortable: false,
          align: "center",
          width: "12%",
        },
        {
          text: "Valor",
          value: "value",
          sortable: false,
          align: "end",
          width: "12%",
        },
      ],
    };
  },
};
</script>