var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.accountTransactions.data,"items-per-page":50,"loading-text":"Carregando... por favor espere"},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('account-transaction-label',{attrs:{"account-transaction":item}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.status}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.date))+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('app-payment-method-chip',{attrs:{"value":item.payment_method}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('app-money-label',{attrs:{"value":item.value,"canceled":item.status == 'canceled'}})]}},{key:"item.account",fn:function(ref){
var item = ref.item;
return [_c('account-label',{attrs:{"account":item.account}})]}}],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }