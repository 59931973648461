<template>
  <div>
    <v-row>
      <v-col cols='12' md='6' >
        <account-multi-select
          v-model="searchParams.accounts"
          :item-value="(val) => val.id"
          :dense="false"
          solo
          flat
          background-color="grey lighten-4"
          multiple
          clearable
          @change="$emit('input')"
        />
      </v-col>
      <v-col>
        <account-plan-select
          v-model="searchParams.account_plan_id"
          :item-value="(val) => val.id"
          :dense="false"
          solo
          flat
          background-color="grey lighten-4"
          multiple
          clearable
          @change="$emit('input')"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols='12' md='3'>
        <app-number-field
          hide-details="auto"
          solo
          flat
          background-color="grey lighten-4"
          v-model="searchParams.value_min"
          label="Valor Minimo"                    
          @change="$emit('input')"
        />
      </v-col>
      <v-col cols='12' md='3'>
        <app-number-field
          hide-details="auto"
          solo
          type="text"
          flat
          background-color="grey lighten-4"
          v-model="searchParams.value_max"
          label="Valor Máximo"
          @change="$emit('input')"
        />
      </v-col>
      <v-col>
        <app-text-field
          type="date"
          solo
          flat
          background-color="grey lighten-4"
          v-model="searchParams.start_date"
          label="Data inicial"
          @change="$emit('input')"
        />
      </v-col>
      <v-col>
        <app-text-field
           type="date"
          solo
          flat
          background-color="grey lighten-4"
          v-model="searchParams.end_date"
          label="Data Final"
          @change="$emit('input')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AccountPlanSelect from "../../account-plan/ui/AccountPlanSelect.vue";
import AccountMultiSelect from "../../account/ui/AccountMultiSelect.vue";
export default {
  components: { AccountPlanSelect, AccountMultiSelect },
  props: {
    searchParams: {},
  },
  computed: {},
 };
</script>

<style   scoped>
</style>