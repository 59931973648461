<template>
  <div>
    <cash-flow-filter @input="select()" :searchParams="searchParams" />
    <br />
    <cash-flow-list
      :loading="loading"
      :accountTransactions="accountTransactions"
    />
    <v-pagination
      v-model="searchParams.page"
      :length="accountTransactions.last_page"
      @input="select($event)"
      :total-visible="9"
    />
  </div>
</template>

<script>
import CashFlowFilter from "@/components/cash-flow/filter/CashFlowFilter.vue";
import CashFlowList from "@/components/cash-flow/lists/CashFlowList.vue";
export default {
  components: { CashFlowFilter, CashFlowList },

  data() {
    return {
      searchParams: {
        page: 1,
        account_plan_id: [],
        start_date: "",
        end_date: "",
      },
      accountTransactions: {},
      loading: false,
    };
  },

  created() {
    this.setAccountPlanId();
  },

  async mounted() {
    await this.select();
  },

  methods: {
    setAccountPlanId() {
      if (this.$route.query.accountPlanId) {
        this.searchParams.account_plan_id = [this.$route.query.accountPlanId];
      }
      if (this.$route.query.start_date) {
        this.searchParams.start_date = this.$route.query.start_date;
      }
      if (this.$route.query.end_date) {
        this.searchParams.end_date = this.$route.query.end_date;
      }
    },

    async select(page = 1) {
      this.searchParams.page = page;
      this.loading = true;
      await this.$http
        .index("account/account-transaction", this.searchParams)
        .then((response) => {
          this.accountTransactions = response.accountTransactions;
          this.loading = false;
        });
    },
  },
};
</script>


<style>
</style>